<script>
import TitleDescriptionSlot from '@/components/general/TitleDescriptionSlot'
import GenericSlider from '@/components/general/GenericSlider'
import TitleDescriptionCardAnchor from '@/components/general/TitleDescriptionCardAnchor'
import PendingMessage from '@/components/general/PendingMessage'
import PositionInvite from '@/components/general/PositionInvite'
import Offer from '@/components/general/Offer'

export default {
  name: 'homepage-individual',
  components: {
    TitleDescriptionSlot,
    GenericSlider,
    TitleDescriptionCardAnchor,
    PendingMessage,
    PositionInvite,
    Offer,
    ConfirmationModal: () => import('@/components/general/ConfirmationModal')
  },
  data () {
    return {
      messagesCounter: 0,
      data: [],
      userApplicationInvites: [],
      userUnreadMessages: [],
      userOffer: [],
      positionSuggestions: [],
      courseSuggestions: [],
      getApplications: [],
      getMatches: [],
      handleUnreadMessages: [],
      pendingRemoteInterviews: [],
      isPositionsLoading: true,
      isCoursesLoading: true,
      isDiscoveryLoading: true,
      showModal: false
    }
  },
  created () {
    this.$store.dispatch('attemptGetPositionSuggestionsHome', { user: this.getUserInfo, aggregator: this.getCompanyDomainAndWorkspace() }).then(dataPositionsSugestions => {
      this.positionSuggestions = dataPositionsSugestions.map(item => {
        if (!item.isFavorite) item.isFavorite = false
        return item
      })
      this.isPositionsLoading = false
    })

    this.$store.dispatch('attemptGetCourseSuggestionsHome', { aggregator: '' }).then(dataCourseSugestions => {
      this.courseSuggestions = dataCourseSugestions
      this.isCoursesLoading = false
    })

    Promise.all([
      this.$store.dispatch('attemptGetUserMessages'),
      this.$store.dispatch('attemptGetOfferLetter')
    ]).then(([dataUnreadMessages, dataOfferLetter]) => {
      this.userUnreadMessages = dataUnreadMessages
      this.userOffer = dataOfferLetter

      if (this.userUnreadMessages.pending_applications > 0 || this.userUnreadMessages.pending_meetings > 0 || this.userUnreadMessages.unread_messages > 0 || this.userUnreadMessages.inprogress_applications) {
        this.messagesCounter = this.userUnreadMessages.unread_messages

        this.$store.dispatch('attemptGetUserMatches').then(data => {
          this.pendingRemoteInterviews = data.filter(match => { return match.position.meeting && match.position.meeting.accepted === false }).sort((a, b) => new Date(a.position.meeting.date) - new Date(b.position.meeting.date)).map(position => position.position)
          this.getMatches = data

          const allPositions = []
          for (const item of data) {
            allPositions.push(item.position)
          }

          this.getApplications = allPositions
          this.handleUnreadMessages = allPositions.filter(d => d.messagesCounter > 0).sort((a, b) => a.title.localeCompare(b.title))
        })
      }

      if (this.userUnreadMessages.pending_applications > 0) {
        this.$store.dispatch('attemptGetUserApplicationInvites').then(data => {
          this.userApplicationInvites = data
        })
      }
    })
    this.$store.commit('updateLastWorkspaceSelected', 'individual')
  },
  methods: {
    handleMatches () {
      this.$store.dispatch('attemptGetUserMatches')
    },
    handleMessages () {
      this.$store.dispatch('attemptGetUserMessages').then(dataUnreadMessages => {
        this.userUnreadMessages = dataUnreadMessages
      })
    },
    handleaUserApplicationInvites () {
      this.$store.dispatch('attemptGetUserApplicationInvites').then(data => {
        this.userApplicationInvites = data
      })
    },
    refreshPendingRemoteInterviews (type) {
      this.$store.dispatch('attemptGetUserMatches').then(data => {
        this.pendingRemoteInterviews = data.filter(match => match.position.meeting && match.position.meeting.accepted === false).sort((a, b) => new Date(a.position.meeting.date) - new Date(b.position.meeting.date)).map(position => position.position)
        if (type === 'confirm') this.showModal = true
      })
    }
  }
}
</script>
<template>
  <div class="homepage-individual--container">
    <div class="default-home-title">
      <div>
        <h2>{{ $t('global:welcome') }}, {{ this.$store.getters.getUser.firstName }}! 👋</h2>
      </div>
      <p>{{ $t('home.greeting.description') }}</p>
    </div>

    <title-description-slot :title="$t('home.messages:not.read.yet')"
      :description="$t('home.messages:not.read.yet.description')" v-if="userUnreadMessages.unread_messages">

      <pending-message v-for="item in handleUnreadMessages" :key="item.id" :position="{ name: item.title, id: item.id }"
        @refreshUnreadMessages="handleMessages()" :company="{ name: item.company.name, image: item.company.logo }"
        :unreadAmount="item.messagesCounter.toString()" />
    </title-description-slot>

    <title-description-slot :title="$t('home.messages:offer.letter')"
      :description="$t('home.messages:offer.letter.description')" v-if="userOffer.length">
      <offer v-for="item in userOffer" :key="item.interview_id" :offer="item"/>
    </title-description-slot>

    <title-description-slot v-if="userApplicationInvites.length" :key="userApplicationInvites.length"
      :title="$t('home.messages:invite.for.position')"
      :description="$t('home.messages:invite.for.position.description')">
      <position-invite v-for="(invite) in userApplicationInvites" :key="invite.id" :inviteId="invite.id"
        @refreshUserApplicationInvites="handleaUserApplicationInvites()" :position="invite.position"
        :company="invite.position.company" />
    </title-description-slot>

    <title-description-card-anchor v-if="userUnreadMessages.inprogress_applications" icon="mdi-list-status" :title="$t('home.card.not.finished.candidacy.title')" :description="$t('home.card.not.finished.candidacy.description')" buttonTo="/my/applications" :buttonText="$t('home.access.not.finished.candidacy')" />

    <GenericSlider v-if="pendingRemoteInterviews.length" :title="$t('home.messages:invite.for.remote.interview')" :items="pendingRemoteInterviews" type="pendingRemote"  @emitGenericSlider="refreshPendingRemoteInterviews" />

    <confirmation-modal
      :maxWidth="500"
      :show="showModal"
      :btnConfirmText="false"
      @cancel="showModal = false"
      :cancelText="$t('global:close')"
      :confirmText="$t('global:candidature.page')"
      :title="$t('invite:confirmation.modal.title')"
      @confirm="$router.push({ name: 'individual.candidature.under.analysis' })"
      :description="$t('invite:confirmation.modal.description')"
    />

    <div class="skeleton-container" v-if="isPositionsLoading">
      <h5> {{ $t('home.positions.suggestions') }}</h5>
      <div class="skeleton-home">
        <v-skeleton-loader class="skeleton-card" type="card-avatar, article, actions" width="296px" height="380px"
          v-for="i in ['a', 'b', 'c']" :key="i" />
      </div>
    </div>

    <div class="skeleton-container" v-if="isCoursesLoading">
      <h5> {{ $t('home.positions.suggestions') }}</h5>
      <div class="skeleton-home">
        <v-skeleton-loader class="skeleton-card" type="card-avatar, article, actions" width="296px" height="380px"
          v-for="i in ['a', 'b', 'c']" :key="i" />
      </div>
    </div>

    <div v-else>
      <GenericSlider v-if="positionSuggestions.length" title="home.positions.suggestions" :items="positionSuggestions" type="positions" />
      <GenericSlider v-if="courseSuggestions.length" title="home.courses.suggestions" :items="courseSuggestions" type="courses" />
    </div>
    <router-view />
  </div>
</template>
<style lang="scss">
.homepage-individual--container {
  .skeleton-container {
    overflow: hidden;
    margin-top: 42px;
    h5 {
      font-size: 16px;
      line-height: 52px;
    }

    .skeleton-home {
      display: flex;
      width: 1200px;

      .skeleton-card {
        margin-right: 20px;
      }
    }
  }
}
</style>
